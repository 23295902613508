// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import compose from 'recompose/compose'
import withStyles from '@material-ui/core/styles/withStyles'
import Modal from 'Modal'
import Button from '@autodisol/ads-js/components/CustomButton'
import { ENABLED_MECHANICAL_COSTS_CONTRAINT } from 'quote/quote-constant'

const styles = () => ({
  formControlLabel: {
    marginBottom: 20,
  },
  actions: {
    marginTop: 20,
  },
})

type Props = {
  classes: Object,
  field: Function,
  setField: Function,
}

const AutomaticallyCalculateMechanicalCosts = ({ classes, field, setField }: Props) => {
  const { t } = useTranslation()
  const { open, openModal, closeModal } = Modal.useModal()

  const mechanicalCost = field('mechanicalCost')
  const mustOpenModal = field('mechanicalCost') > 0 && !field('automallyCalculateMechanicalCosts')

  const handleCheckboxChange = event => {
    const { name, checked } = event.target

    const shouldZeroMechanicalCost =
      ENABLED_MECHANICAL_COSTS_CONTRAINT &&
      Number(field('additionalMechanicalCosts')) > 0 &&
      mechanicalCost === '' &&
      !checked

    if (shouldZeroMechanicalCost) {
      setField('mechanicalCost', 0)
    }

    if (mustOpenModal) {
      openModal()
      return
    }

    setField(name, checked)
  }

  const handleClickConfirm = () => {
    setField('mechanicalCost', '')
    setField('automallyCalculateMechanicalCosts', true)

    closeModal()
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={field('automallyCalculateMechanicalCosts')}
            onChange={handleCheckboxChange}
            color="primary"
            name="automallyCalculateMechanicalCosts"
          />
        }
        className={classes.formControlLabel}
        label={t('quote.mechanicalMaintenanceCosts.automaticallyCalculate')}
      />

      {open && (
        <Modal open={open} handleClose={closeModal}>
          <Modal.Content>{t('quote.mechanicalMaintenanceCosts.modal.content')}</Modal.Content>

          <Modal.Actions>
            <div className={classes.actions}>
              <Button
                colorType="secondary"
                height="short"
                variant="contained"
                onClick={closeModal}
                style={{ width: 'calc(50% - 20px)', marginRight: 10 }}
              >
                {t('globals.cancel')}
              </Button>

              <Button
                colorType="green"
                height="short"
                variant="contained"
                style={{ width: 'calc(50% - 20px)', marginLeft: 10 }}
                onClick={handleClickConfirm}
              >
                {t('globals.confirm')}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default (compose(withStyles(styles))(AutomaticallyCalculateMechanicalCosts): any)
