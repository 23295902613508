// @flow

type PrevData = {
  mechanical?: number,
  mechanicalExtra?: number,
}

type NewData = {
  mechanicalCost?: string,
  additionalMechanicalCosts?: string,
}

type ReturnType = PrevData

export const getCosts = (prevData: PrevData, newData: NewData): ReturnType => {
  const data = {}

  if (typeof prevData.mechanical === 'number') {
    data.mechanical = newData.mechanicalCost !== '' ? Number(newData.mechanicalCost) : 0
  }

  if (typeof prevData.mechanicalExtra === 'number') {
    data.mechanicalExtra = newData.additionalMechanicalCosts
      ? Number(newData.additionalMechanicalCosts)
      : 0
  }

  if (prevData.mechanical === undefined) {
    data.mechanical = newData.mechanicalCost ? Number(newData.mechanicalCost) : undefined
  }

  if (prevData.mechanicalExtra === undefined) {
    data.mechanicalExtra = newData.additionalMechanicalCosts
      ? Number(newData.additionalMechanicalCosts)
      : undefined
  }

  return data
}
