// @flow

import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Loader from '@material-ui/core/CircularProgress'
import LabeledSeparator from '@autodisol/ads-js/components/LabeledSeparator'
import Multiselect from '@autodisol/ads-js/components/Multiselect'
import { connect as withRedux } from 'react-redux'
import { notify } from 'sharyn/redux/actions'
import { genericErrorNotification } from 'app/notifications'
import arr from 'sharyn/util/arr'
import cond from 'sharyn/util/cond'
import { PLATE_EXAMPLES, REFERENCE_EXAMPLES } from 'quote/quote-constant'
import { MODES } from 'utils/constants/modes'
import {
  getMakesDataQuery,
  getFamiliesDataQuery,
  getSubFamiliesDataQuery,
  getModelsDataQuery,
  getVersionsDataQuery,
  getBodiesDataQuery,
  getEnergiesDataQuery,
  getGearboxesDataQuery,
  getEuroStandardsDataQuery,
  getRegTypesDataQuery,
  createQuoteMutation,
  updateQuoteMutation,
} from 'quote/quote-queries'
import { useTranslation } from 'react-i18next'
import i18next from 'services/translations/config'
import { elementIdentify, setCurrentDraftQuoteId } from '_client/redux/actions'
import BikeForm from 'quote/cmp/DetailsForm/BikeForm'
import { getFilteredResults } from 'quote/cmp/DetailsForm/functions'
import { IDENTIFICATION_CATEGORIES } from 'utils/constants'
import { isInteger } from 'utils/number'
import FieldLabel from 'quote/cmp/FieldLabel'
import { getCosts } from 'quote/cmp/functions'

import type { Mode } from 'types/modes'
import type { IdentificationMode } from 'quote/types'

const getQuoteInputVehicleData = (electrical: Object | null): ?Object => {
  if (!electrical) return undefined

  const data = {}

  const batteryNumber = electrical.batteryNumber ?? null
  const electricEngineNumber = electrical.electricEngineNumber ?? null
  const batteryCapacityKwh = electrical.batteryCapacityKwh ?? null
  const batteryWarranty = electrical.batteryWarranty ?? null

  if (batteryNumber !== null) data.batteryNumber = Number(batteryNumber)
  if (electricEngineNumber !== null) data.electricEngineNumber = Number(electricEngineNumber)
  if (batteryCapacityKwh !== null) data.batteryCapacityKwh = Number(batteryCapacityKwh)
  if (batteryWarranty !== null) data.batteryWarranty = Number(batteryWarranty)

  return data
}

const upperOnChange =
  ({ onChange }) =>
  e => {
    e.target.value = e.target.value.toUpperCase()
    onChange(e)
  }

const loadEnergies = async ({ getEnergiesData, setEnergyList, mode }) => {
  try {
    const list = await getEnergiesData({
      variables: {
        category:
          mode === MODES.standard_bike
            ? IDENTIFICATION_CATEGORIES.BIKE
            : IDENTIFICATION_CATEGORIES.CAR,
      },
    })

    setEnergyList(
      list.data.get_energies_list.map(energy => ({
        value: energy,
        label: i18next.t(`quote.energy.${energy}`),
      })),
    )
  } catch (e) {
    return false
  }
  return true
}

const loadBodies = async ({ getBodiesData, setBodyList, mode }) => {
  try {
    const list = await getBodiesData({
      variables: {
        category:
          mode === MODES.standard_bike
            ? IDENTIFICATION_CATEGORIES.BIKE
            : IDENTIFICATION_CATEGORIES.CAR,
      },
    })

    setBodyList(
      list.data.get_bodies_list.map(body => ({
        value: body,
        label: i18next.t(`quote.body.${body}`),
      })),
    )
  } catch (e) {
    return false
  }
  return true
}

const loadGearboxes = async ({ getGearboxData, setGearboxList, mode }) => {
  try {
    const list = await getGearboxData({
      variables: {
        category:
          mode === MODES.standard_bike
            ? IDENTIFICATION_CATEGORIES.BIKE
            : IDENTIFICATION_CATEGORIES.CAR,
      },
    })

    setGearboxList(
      list.data.get_gearboxes_list.map(gearbox => ({
        value: gearbox,
        label: i18next.t(`quote.gearboxes.${gearbox}`),
      })),
    )
  } catch (e) {
    return false
  }
  return true
}

const loadEuroStandards = async ({ getEuroStandardsData, setEuroStandardList }) => {
  try {
    const list = await getEuroStandardsData()

    setEuroStandardList(
      list.data.get_eurostandards_list.map(standard => ({
        value: standard,
        label: standard,
      })),
    )
  } catch (e) {
    return false
  }
  return true
}

const loadRegTypes = async ({ getRegTypeData, setRegTypeList, mode }) => {
  try {
    const list = await getRegTypeData({
      variables: {
        category:
          mode === MODES.standard_bike
            ? IDENTIFICATION_CATEGORIES.BIKE
            : IDENTIFICATION_CATEGORIES.CAR,
      },
    })

    setRegTypeList(
      list.data.get_regtypes_list.map(type => ({
        value: type,
        label: i18next.t(`quote.regType.${type}`),
      })),
    )
  } catch (e) {
    return false
  }
  return true
}

const getFilteredMakes =
  ({ isManual, makeList, getMakesData, identificationCountry }) =>
  async pattern => {
    if (!isManual) {
      return makeList.filter(make => make.name.toLowerCase().indexOf(pattern) === 0)
    }
    const response = await getMakesData({
      variables: {
        filters: {
          nameLike: pattern,
          country: identificationCountry,
        },
      },
    })

    const { data } = response

    const options =
      data.get_makes_data &&
      data.get_makes_data.map(make => ({
        ...make,
        value: `${make.name}${make.type}`,
        label: `${make.name} (${i18next.t(make.type)})`,
      }))

    return options
  }

const loadMakes = async ({ makeList, setMakeList, getMakesData, identificationCountry }) => {
  if (makeList.length === 0) {
    try {
      const makes = await getFilteredMakes({
        getMakesData,
        isManual: true,
        makeList: [],
        identificationCountry,
      })()
      setMakeList(makes)
    } catch (e) {
      return false
    }
  }
  return true
}

const getFilteredRanges =
  ({ getFamiliesData, selectedMake, css, rangeList, identificationCountry, getSubFamiliesData }) =>
  async pattern => {
    if (selectedMake?.families) {
      return rangeList.filter(range => range.data.name.toLowerCase().indexOf(pattern) === 0)
    }
    const ranges = []
    const families = await getFamiliesData({
      variables: {
        filters: {
          nameLike: pattern,
          makes: [selectedMake?.id],
          types: [selectedMake?.type],
          country: identificationCountry,
        },
      },
    })

    const subFamilies = await getSubFamiliesData({
      variables: {
        filters: {
          makes: [selectedMake?.id],
          families: pattern ? families.data.get_families_data.map(fam => +fam.id) : null,
          types: [selectedMake?.type],
          country: identificationCountry,
        },
      },
    })
    families.data.get_families_data &&
      families.data.get_families_data.forEach(family => {
        ranges.push({
          data: family,
          type: 'family',
          parent: null,
          parentName: null,
          label: <span className={css.customOptGroup}>{family.name}</span>,
          value: family.id,
        })
        const subFams =
          subFamilies.data.get_subfamilies_data &&
          subFamilies.data.get_subfamilies_data.filter(
            subFam => subFam.familyId === parseInt(family.id, 10),
          )
        subFams &&
          subFams.forEach(subFam =>
            ranges.push({
              data: subFam,
              type: 'subFamily',
              parent: family.id,
              parentName: family.name,
              label: subFam.name,
              value: subFam.id,
            }),
          )
      })

    return ranges
  }

const handleMakeChange =
  ({
    setRangeList,
    setSelectedMake,
    setSelectedSubFamily,
    selectedSubFamily,
    setSelectedModel,
    setSelectedVersion,
    setModelList,
    getFilteredModels,
    getFamiliesData,
    getSubFamiliesData,
    getModelData,
    identificationCountry,
    css,
  }) =>
  async make => {
    setSelectedMake(make)
    setSelectedSubFamily(null)
    setSelectedModel(null)
    setSelectedVersion(null)
    const providedRangeList = []
    if (identificationCountry === 'es') {
      setModelList([])
      const models = await getFilteredModels({
        getModelData,
        selectedMake: { type: make.type, id: make.id },
        selectedSubFamily,
        modelList: [],
        identificationCountry,
      })()
      setModelList(models)
    } else if (make?.families) {
      make.families.forEach(family => {
        providedRangeList.push({
          type: 'family',
          parent: null,
          parentName: null,
          data: family,
          label: `-- ${family.name} --`,
          value: family.id,
        })
        family.subFamilies.forEach(subFamily => {
          providedRangeList.push({
            type: 'subFamily',
            parent: family.id,
            parentName: family.name,
            data: subFamily,
            label: subFamily.name,
            value: subFamily.id,
          })
        })
      })
      setRangeList(providedRangeList)
    } else {
      setRangeList([])
      const ranges = await getFilteredRanges({
        getFamiliesData,
        getSubFamiliesData,
        selectedMake: make,
        css,
        rangeList: [],
        identificationCountry,
      })()
      setRangeList(ranges)
    }
  }

const getFilteredModels =
  ({ getModelData, selectedMake, selectedSubFamily, modelList, identificationCountry }) =>
  async pattern => {
    if (selectedSubFamily) {
      if (selectedSubFamily?.data.models) {
        return modelList.filter(model => model.name.toLowerCase().indexOf(pattern) === 0)
      }
    }

    const filters = {
      nameLike: pattern,
      makes: [selectedMake?.id],
      types: [selectedMake?.type],
      country: identificationCountry,
    }

    const list = await getModelData({
      variables: {
        filters:
          identificationCountry !== 'es'
            ? { ...filters, subFamilies: [+selectedSubFamily?.data?.id] }
            : filters,
      },
    })

    const options =
      list.data.get_models_data &&
      list.data.get_models_data.map(model => ({
        ...model,
        value: model.id,
        label: (
          <span>
            {model.name}
            <Typography component="span" variant="caption">
              {model.saleStart} / {model.saleEnd}
            </Typography>
          </span>
        ),
      }))

    return options
  }

const handleSubFamilyChange =
  ({
    setSelectedSubFamily,
    setSelectedModel,
    setSelectedVersion,
    getModelData,
    setModelList,
    selectedMake,
    identificationCountry,
  }) =>
  async subFamily => {
    setSelectedSubFamily(subFamily)
    setSelectedModel(null)
    setSelectedVersion(null)
    if (subFamily?.data.models) {
      const providedModelList = subFamily.data.models.map(model => ({
        ...model,
        value: model.id,
        label: (
          <span>
            {model.name}
            <Typography component="span" variant="caption">
              {model.saleStart} / {model.saleEnd}
            </Typography>
          </span>
        ),
      }))
      setModelList(providedModelList)
    } else {
      setModelList([])
      const models = await getFilteredModels({
        getModelData,
        selectedMake,
        selectedSubFamily: subFamily,
        identificationCountry,
        modelList: [],
      })()
      setModelList(models)
    }
  }

const getFilteredVersions =
  ({ getVersionsData, selectedMake, selectedModel, versionList, identificationCountry }) =>
  async pattern => {
    if (selectedModel.versions) {
      return versionList.filter(version => version.name.toLowerCase().indexOf(pattern) === 0)
    }

    const list = await getVersionsData({
      variables: {
        filters: {
          nameLike: pattern,
          makes: [selectedMake?.id],
          models: [+selectedModel?.value],
          types: [selectedMake?.type],
          country: identificationCountry,
        },
      },
    })

    return (
      list.data.get_versions_data &&
      list.data.get_versions_data.map(version => {
        const cleanVersion = {}
        Object.keys(version).forEach(prop => {
          if (version[prop] && version[prop] !== '') {
            cleanVersion[prop] = version[prop]
          }
        })
        return {
          ...cleanVersion,
          value: version.id,
          label: (
            <span>
              {version.name}&nbsp;{version.trimLevel}
              <Typography component="span" variant="caption">
                {version.saleStart} / {version.saleEnd}
              </Typography>
            </span>
          ),
        }
      })
    )
  }

const handleModelChange =
  ({
    setSelectedModel,
    setSelectedVersion,
    getVersionsData,
    setVersionList,
    selectedMake,
    identificationCountry,
  }) =>
  async model => {
    setSelectedModel(model)
    setSelectedVersion(null)
    if (model.versions) {
      const providedVersionList = model.versions.map(version => ({
        ...version,
        value: version.id,
        label: (
          <span>
            {version.name}&nbsp;{version.trimLevel}
            <Typography component="span" variant="caption">
              {version.saleStart} / {version.saleEnd}
            </Typography>
          </span>
        ),
      }))
      setVersionList(providedVersionList)
    } else {
      setVersionList([])
      const versions = await getFilteredVersions({
        getVersionsData,
        selectedMake,
        selectedModel: model,
        versionList: [],
        identificationCountry,
      })()
      setVersionList(versions)
    }
  }

const handleVersionChange =
  ({
    i,
    fields,
    createQuote,
    setSelectedVersion,
    selectedMake,
    selectedModel,
    selectedSubFamily,
    isEditionMode,
    isCorrectionMode,
    preselectedVersion,
    id,
    identificationCountry,
    updateQuote,
    setUpdateQuote,
    mode,
    setField,
  }) =>
  async version => {
    setSelectedVersion(version)

    const options = i?.options?.map(({ name, price }) => ({ name, price }))
    const vehicle = getQuoteInputVehicleData(version?.electrical)

    setField('vehicle', vehicle)

    const prevCosts = {
      mechanical: i?.prices?.find(element => element.type === 'mechanical')?.amount,
      mechanicalExtra: i?.prices?.find(element => element.type === 'mechanical_extra')?.amount,
    }

    const quoteInput: Object = {
      id: id && id,
      category: mode,
      plate: fields.plate,
      vin: fields.vin,
      make: selectedMake?.name,
      type: selectedMake?.type,
      family: selectedSubFamily?.parentName,
      subFamily: selectedSubFamily?.data.name,
      model: selectedModel?.name,
      startModelPhase: selectedModel?.saleStart
        ? { date: selectedModel?.saleStart, timezone: 'UTC' }
        : null,
      endModelPhase: selectedModel?.saleEnd
        ? { date: selectedModel?.saleEnd, timezone: 'UTC' }
        : null,
      catalogId: version?.id,
      version: version?.name,
      trimLevel: version?.trimLevel,
      startVersionPhase: version?.saleStart ? { date: version?.saleStart, timezone: 'UTC' } : null,
      endVersionPhase: version?.saleEnd ? { date: version?.saleEnd, timezone: 'UTC' } : null,
      energy: version?.energy,
      hpPower: version?.hpPower,
      kwPower: version?.kwPower,
      displacement: version?.displacement,
      gearbox: version?.gearbox,
      gears: version?.gears,
      co2: (fields.co2 && +fields.co2) ?? (i?.regCo2 && +i?.regCo2),
      color: i?.regColor ?? i?.color ?? fields.color,
      productionCountry: 'FR',
      regType: i?.regType ?? fields.regType,
      body: fields.body ?? version?.body,
      doors: version?.doors,
      seats: version?.seats === 0 ? null : version?.seats,
      deliveryKm: fields.deliveryKm && parseInt(fields.deliveryKm, 10),
      releaseDate: fields.firstRegDate && {
        date: fields.firstRegDate,
        timezone: 'UTC',
      },
      deliveryDate: fields.deliveryDate && {
        date: fields.deliveryDate,
        timezone: 'UTC',
      },
      catalogSource: 'autovista',
      euroStandard: fields.euroStandard ?? version?.euroStandard,
      firstSellPrice: version?.price,
      lastRegDate: fields.lastRegDate && {
        date: fields.lastRegDate,
        timezone: 'UTC',
      },
      regEnergy: i?.regEnergy,
      vehicle,
      ...(identificationCountry === 'fr' && {
        taxPower: version?.taxPower ?? (fields.taxPower && +fields.taxPower),
      }),
      costs: getCosts(prevCosts, fields),
      // $FlowIgnore[exponential-spread]
      ...(mode === MODES.resval
        ? { reference: fields.reference }
        : { currentKm: fields.currentKm && parseInt(fields.currentKm, 10) }),
    }

    if (version) {
      if (!isEditionMode && !isCorrectionMode) {
        if (!preselectedVersion) {
          createQuote({
            variables: {
              // $FlowIgnore[exponential-spread]
              quoteInput: { ...quoteInput, ...(options && { options }) },
            },
          })
        } else {
          setUpdateQuote(true)
          updateQuote({
            variables: {
              quoteInput,
            },
          })
        }
      }
    }
  }

const getRegType = (regTypeOfSelectedVersion, regTypeField) => {
  if (regTypeOfSelectedVersion) return regTypeOfSelectedVersion

  if (regTypeField) return regTypeField

  return ''
}

const DetailsFormJSX = ({
  classes: css,
  identifiedVehicle: i,
  onChange,
  field,
  fields,
  setField,
  selectedMake,
  setSelectedMake,
  setSelectedSubFamily,
  setSelectedModel,
  setSelectedVersion,
  selectedSubFamily,
  selectedModel,
  selectedVersion,
  isManual,
  dispatch,
  isEditionMode,
  isCorrectionMode,
  preselectedElements,
  identifyCountry,
  setUpdateQuote,
  mode,
  identificationMode,
}: {
  classes: Object,
  field: Function,
  fields: Object,
  setField: Function,
  identifiedVehicle?: Object,
  onChange: Function,
  selectedMake?: Object,
  setSelectedMake: Function,
  setSelectedSubFamily: Function,
  setSelectedModel: Function,
  setSelectedVersion: Function,
  selectedSubFamily?: Object,
  selectedModel?: Object,
  selectedVersion?: Object,
  isManual?: boolean,
  dispatch: Function,
  isEditionMode?: boolean,
  isCorrectionMode?: boolean,
  preselectedElements?: Object,
  identifyCountry?: string,
  setUpdateQuote: Function,
  mode: Mode,
  identificationMode: IdentificationMode,
}) => {
  const { t } = useTranslation()
  const [makeList, setMakeList] = useState([])
  const [rangeList, setRangeList] = useState([])
  const [modelList, setModelList] = useState([])
  const [versionList, setVersionList] = useState([])
  const [bodyList, setBodyList] = useState([])
  const [energyList, setEnergyList] = useState([])
  const [gearboxList, setGearboxList] = useState([])
  const [euroStandardList, setEuroStandardList] = useState([])
  const [regTypeList, setRegTypeList] = useState([])
  const identificationCountry = i?.identificationCountry
    ? i?.identificationCountry
    : identifyCountry

  const [getMakesData] = useLazyQuery(getMakesDataQuery)
  const [getFamiliesData] = useLazyQuery(getFamiliesDataQuery)
  const [getSubFamiliesData] = useLazyQuery(getSubFamiliesDataQuery)
  const [getModelData] = useLazyQuery(getModelsDataQuery)
  const [getVersionsData] = useLazyQuery(getVersionsDataQuery)
  const [getEnergiesData] = useLazyQuery(getEnergiesDataQuery)
  const [getBodiesData] = useLazyQuery(getBodiesDataQuery)
  const [getGearboxData] = useLazyQuery(getGearboxesDataQuery)
  const [getEuroStandardsData] = useLazyQuery(getEuroStandardsDataQuery)
  const [getRegTypeData] = useLazyQuery(getRegTypesDataQuery)
  const [createQuote, { loading: loadCreateQuote }] = useMutation(createQuoteMutation, {
    // eslint-disable-next-line
    onCompleted: ({ create_quote }) => {
      dispatch(setCurrentDraftQuoteId(create_quote.id))
      // eslint-disable-next-line
      if (create_quote) {
        dispatch(
          elementIdentify({
            preselectedVersion: selectedVersion,
            // eslint-disable-next-line camelcase
            id: create_quote?.id,
            // eslint-disable-next-line
            furtherStepsDisabled: create_quote && false,
            electricType: {
              isElectric: create_quote?.isElectric,
              isElectrified: create_quote?.isElectrified,
              isElectrifiedWithCharge: create_quote?.isElectrifiedWithCharge,
            },
          }),
        )
      } else {
        dispatch(notify(genericErrorNotification))
      }
    },
  })

  const [updateQuote, { loading: loadUpdateQuote }] = useMutation(updateQuoteMutation, {
    onCompleted: () => {
      setUpdateQuote(false)
    },
  })

  useEffect(() => {
    loadMakes({ makeList, setMakeList, getMakesData, identificationCountry })
    loadEnergies({ getEnergiesData, setEnergyList, mode })
    loadBodies({ getBodiesData, setBodyList, mode })
    loadGearboxes({ getGearboxData, setGearboxList, mode })
    loadEuroStandards({ getEuroStandardsData, setEuroStandardList })
    loadRegTypes({ getRegTypeData, setRegTypeList, mode })

    let providedMakeList
    let firstSubfamily
    let providedModelList
    const providedRangeList = []
    if (identificationCountry === 'es') {
      if (i?.makes) {
        providedMakeList = arr(i?.makes).map(make => ({
          ...make,
          value: `${make.name}${make.type}`,
          label: `${make.name} (${make.type})`,
        }))
        setMakeList(providedMakeList)
        setSelectedMake(providedMakeList[0])
      }
      if (providedMakeList && providedMakeList[0] && providedMakeList[0].families) {
        providedModelList = arr(providedMakeList[0].families[0].subFamilies[0].models).map(
          model => ({
            ...model,
            value: model.id,
            label: (
              <span>
                {model.name}
                {model.saleStart && model.saleEnd && (
                  <Typography component="span" variant="caption">
                    {model.saleStart} / {model.saleEnd}
                  </Typography>
                )}
              </span>
            ),
          }),
        )
        setModelList(providedModelList)
        setSelectedModel(providedModelList[0])
      } else {
        providedModelList = arr(i?.models).map(model => ({
          ...model,
          value: model.id,
          label: (
            <span>
              {model.name}
              {model.saleStart && model.saleEnd && (
                <Typography component="span" variant="caption">
                  {model.saleStart} / {model.saleEnd}
                </Typography>
              )}
            </span>
          ),
        }))
        setModelList(providedModelList)
        setSelectedModel(providedModelList[0])
      }
      if (providedModelList && providedModelList[0] && providedModelList[0].versions) {
        const providedVersionList = arr(providedModelList[0].versions).map(version => ({
          ...version,
          value: version.id,
          label: (
            <span>
              {version.name}&nbsp;{version.trimLevel}
              {version.saleStart && version.saleEnd && (
                <Typography component="span" variant="caption">
                  {version.saleStart} / {version.saleEnd}
                </Typography>
              )}
            </span>
          ),
        }))
        setVersionList(providedVersionList)
        if (
          isEditionMode ||
          isCorrectionMode ||
          selectedVersion === preselectedElements?.preselectedElements?.preselectedVersion
        ) {
          setSelectedVersion(providedVersionList[0])
        } else if (preselectedElements?.preselectedVersion) {
          setSelectedVersion({
            ...preselectedElements?.preselectedVersion,
            value: preselectedElements?.preselectedVersion.id,
            label: (
              <span>
                {preselectedElements?.preselectedVersion.name}&nbsp;
                {preselectedElements?.preselectedVersion.trimLevel}
                {preselectedElements?.preselectedVersion.saleStart &&
                  preselectedElements?.preselectedVersion.saleEnd && (
                    <Typography component="span" variant="caption">
                      {preselectedElements?.preselectedVersion.saleStart} /{' '}
                      {preselectedElements?.preselectedVersion.saleEnd}
                    </Typography>
                  )}
              </span>
            ),
          })
        }
      }
    } else {
      if (i?.makes) {
        providedMakeList = arr(i?.makes).map(make => ({
          ...make,
          value: `${make.name}${make.type}`,
          label: `${make.name} (${make.type})`,
        }))
        setMakeList(providedMakeList)
        setSelectedMake(providedMakeList[0])
      }
      if (providedMakeList && providedMakeList[0] && providedMakeList[0].families) {
        providedMakeList[0].families.forEach(family => {
          providedRangeList.push({
            type: 'family',
            parent: null,
            parentName: null,
            data: family,
            label: `-- ${family.name} --`,
            value: family.id,
          })
          family.subFamilies.forEach(subFamily => {
            providedRangeList.push({
              type: 'subFamily',
              parent: family.id,
              parentName: family.name,
              data: subFamily,
              label: subFamily.name,
              value: subFamily.id,
            })
          })
        })
        setRangeList(providedRangeList)
        // eslint-disable-next-line prefer-destructuring
        const providedRangeListFormatted = providedRangeList.filter(
          range => range.type === 'subFamily',
        )

        if (providedRangeListFormatted.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          firstSubfamily = providedRangeListFormatted[0]
          setSelectedSubFamily(firstSubfamily)
        }
      }
      if (firstSubfamily) {
        providedModelList = arr(firstSubfamily?.data.models).map(model => ({
          ...model,
          value: model.id,
          label: (
            <span>
              {model.name}
              <Typography component="span" variant="caption">
                {model.saleStart} / {model.saleEnd}
              </Typography>
            </span>
          ),
        }))
        setModelList(providedModelList)
        setSelectedModel(providedModelList.length === 1 ? providedModelList[0] : null)
      }
      if (providedModelList && providedModelList[0] && providedModelList[0].versions) {
        const providedVersionList = arr(providedModelList[0].versions).map(version => ({
          ...version,
          value: version.id,
          label: (
            <span>
              {version.name}&nbsp;{version.trimLevel}
              <Typography component="span" variant="caption">
                {version.saleStart} / {version.saleEnd}
              </Typography>
            </span>
          ),
        }))
        setVersionList(providedVersionList)
        if (
          isEditionMode ||
          isCorrectionMode ||
          selectedVersion === preselectedElements?.preselectedElements?.preselectedVersion
        ) {
          setSelectedVersion(providedVersionList[0])
        } else if (preselectedElements?.preselectedVersion) {
          setSelectedVersion({
            ...preselectedElements?.preselectedVersion,
            value: preselectedElements?.preselectedVersion.id,
            label: (
              <span>
                {preselectedElements?.preselectedVersion.name}&nbsp;
                {preselectedElements?.preselectedVersion.trimLevel}
                <Typography component="span" variant="caption">
                  {preselectedElements?.preselectedVersion.saleStart} /{' '}
                  {preselectedElements?.preselectedVersion.saleEnd}
                </Typography>
              </span>
            ),
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectChange = (fieldName: string) => (data: Object) => {
    setField(fieldName, data.value)
  }

  const regType = getRegType(selectedVersion?.regType, field('regType'))
  const typeOfRegistrationDocumentValue = regType
    ? {
        value: regType,
        label: t(`quote.regType.${regType}`),
      }
    : regType

  if (mode === MODES.standard_bike) {
    return (
      <BikeForm
        identifiedVehicle={i}
        onChange={onChange}
        field={field}
        selectedVersion={selectedVersion}
        handleSelectChange={handleSelectChange}
        energyList={energyList}
        gearboxList={gearboxList}
        regTypeList={regTypeList}
        typeOfRegistrationDocumentValue={typeOfRegistrationDocumentValue}
        identificationMode={identificationMode}
      />
    )
  }

  const handleChangeInputNumber = e => {
    const { value, name } = e.target

    if (isInteger(value)) {
      onChange({ target: { name, value: Number(value) } })
    }
  }

  return (
    <div className={css.formContainer}>
      <div className={css.formFields}>
        {mode === MODES.resval && (
          <>
            <LabeledSeparator title={t('quote.reference')} className={css.separator} />
            <TextField
              name="reference"
              autoComplete="off"
              label={t('quote.reference')}
              value={field('reference')}
              InputLabelProps={{ shrink: true }}
              className={css.formInput}
              placeholder={`${t('globals.exampleShortColon')} ${
                REFERENCE_EXAMPLES[identifyCountry] ?? REFERENCE_EXAMPLES.default
              }`}
              onChange={upperOnChange({ onChange })}
            />
          </>
        )}
        <LabeledSeparator title={t('globals.identification')} className={css.separator} />
        <TextField
          name="plate"
          autoComplete="off"
          label={<FieldLabel label={t('quote.plate')} isRequired />}
          value={i?.plate ?? field('plate')}
          InputLabelProps={{ shrink: true }}
          disabled={!!i?.plate}
          className={css.formInput}
          placeholder={`${t('globals.exampleColon')} ${
            PLATE_EXAMPLES[identifyCountry] ?? PLATE_EXAMPLES.default
          }`}
          onChange={upperOnChange({ onChange })}
        />
        <Typography variant="caption" color="secondary" className={css.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(A)' })}
        </Typography>
        <TextField
          name="vin"
          autoComplete="off"
          label={<FieldLabel label={t('quote.vin')} isRequired />}
          value={i?.vin ?? field('vin')}
          InputLabelProps={{ shrink: true }}
          disabled={!!i?.vin}
          className={css.formInput}
          placeholder={`${t('globals.exampleColon')} 1ABCD23456E789123`}
          inputProps={{ maxLength: 17 }}
          {...{ onChange }}
        />
        <Typography variant="caption" color="secondary" className={css.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(E)' })}
        </Typography>
        <LabeledSeparator title={t('quote.identification.version')} className={css.separator} />
        <Multiselect
          className={css.formInput}
          label={<FieldLabel label={t('quote.make')} isRequired />}
          placeholder={t('quote.identification.select.brand')}
          defaultOptions={makeList}
          value={selectedMake}
          getData={getFilteredMakes({
            isManual,
            getMakesData,
            makeList,
            identificationCountry,
          })}
          handleChange={make =>
            handleMakeChange({
              setRangeList,
              setSelectedMake,
              setSelectedSubFamily,
              selectedSubFamily,
              setSelectedModel,
              setSelectedVersion,
              setModelList,
              getFilteredModels,
              identifiedVehicle: i,
              identificationCountry,
              fields,
              getFamiliesData,
              getSubFamiliesData,
              getModelData,
              css,
            })(make)
          }
          noOptionsMessage={() => t('quote.noResults')}
          withDebounce
        />
        <Typography variant="caption" color="secondary" className={css.helper}>
          {t('quote.identification.fieldOnRegistrationDocument', { field: '(D.1)' })}
        </Typography>
        {identificationCountry !== 'es' && selectedMake && (
          <>
            <Multiselect
              className={css.formInput}
              label={t('quote.identification.range')}
              placeholder={t('quote.identification.select.range')}
              defaultOptions={rangeList}
              getData={getFilteredRanges({
                getFamiliesData,
                getSubFamiliesData,
                selectedMake,
                css,
                rangeList,
                identificationCountry,
              })}
              value={selectedSubFamily}
              handleChange={range =>
                handleSubFamilyChange({
                  identifiedVehicle: i,
                  identificationCountry,
                  fields,
                  rangeList,
                  setSelectedSubFamily,
                  setSelectedModel,
                  setSelectedVersion,
                  dispatch,
                  setModelList,
                  selectedMake,
                  getModelData,
                })(range)
              }
              noOptionsMessage={() => t('quote.noResults')}
              isOptionDisabled={option => option.type === 'family'}
              withDebounce
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(D.3)' })}
            </Typography>
          </>
        )}
        {(selectedSubFamily || (identificationCountry === 'es' && selectedMake)) && (
          <Multiselect
            className={css.formInput}
            label={<FieldLabel label={t('quote.model')} isRequired />}
            placeholder={t('quote.identification.select.model')}
            defaultOptions={modelList}
            getData={getFilteredModels({
              getModelData,
              selectedMake,
              selectedSubFamily,
              modelList,
              identificationCountry,
            })}
            value={selectedModel}
            handleChange={model => {
              handleModelChange({
                setSelectedModel,
                setSelectedVersion,
                dispatch,
                setVersionList,
                selectedMake,
                identificationCountry,
                getFilteredVersions,
                getVersionsData,
              })(model)
            }}
            noOptionsMessage={() => t('quote.noResults')}
            withDebounce
          />
        )}
        {selectedModel && (
          <>
            <Multiselect
              className={css.formInput}
              label={<FieldLabel label={t('quote.identification.version')} isRequired />}
              placeholder={t('quote.identification.select.version')}
              defaultOptions={versionList}
              getData={event =>
                getFilteredVersions({
                  getVersionsData,
                  selectedMake,
                  selectedModel,
                  versionList,
                  identificationCountry,
                })(event)
              }
              value={selectedVersion}
              handleChange={version =>
                handleVersionChange({
                  i,
                  setSelectedVersion,
                  dispatch,
                  fields,
                  selectedMake,
                  selectedModel,
                  selectedSubFamily,
                  isEditionMode,
                  isCorrectionMode,
                  identificationCountry,
                  preselectedVersion: preselectedElements?.preselectedVersion,
                  id: preselectedElements?.id,
                  createQuote,
                  updateQuote,
                  setUpdateQuote,
                  mode,
                  setField,
                })(version)
              }
              noOptionsMessage={() => t('quote.noResults')}
              withDebounce
            />

            {loadUpdateQuote && (
              <Typography variant="caption" className={css.draftCreationHelper}>
                <Loader size={25} />
              </Typography>
            )}
            {loadCreateQuote && (
              <Typography variant="caption" className={css.draftCreationHelper}>
                <Loader size={25} />
                <span className={css.draftCreationHelperSpan}>
                  {t('quote.identification.draftCreation')}
                </span>
              </Typography>
            )}
          </>
        )}
        {selectedVersion && (
          <>
            <LabeledSeparator title={t('quote.identification.engine')} className={css.separator} />
            <Multiselect
              className={css.formInput}
              label={`${t('quote.identification.fuel')} / ${t('quote.identification.energy')}`}
              placeholder={t('quote.identification.select.energy')}
              defaultOptions={energyList}
              value={
                selectedVersion.energy && {
                  value: selectedVersion.energy,
                  label: t(`quote.energy.${selectedVersion.energy}`),
                }
              }
              disabled={!!selectedVersion.energy}
              handleChange={handleSelectChange('energy')}
              noOptionsMessage={() => t('quote.noResults')}
              getData={event => getFilteredResults({ list: energyList })(event)}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(P.3)' })}
            </Typography>
            <Multiselect
              className={css.formInput}
              label={t('quote.gearbox')}
              placeholder={t('quote.identification.select.gearbox')}
              defaultOptions={gearboxList}
              value={
                selectedVersion.gearbox && {
                  value: selectedVersion.gearbox,
                  label: t(`quote.gearboxes.${selectedVersion.gearbox}`),
                }
              }
              disabled={!!selectedVersion.gearbox}
              handleChange={handleSelectChange('gearbox')}
              noOptionsMessage={() => t('quote.noResults')}
              getData={event => getFilteredResults({ list: gearboxList })(event)}
            />
            <LabeledSeparator title={t('quote.identification.power')} className={css.separator} />
            <TextField
              name="hpPower"
              label={t('quote.identification.powerCh')}
              value={selectedVersion.hpPower ?? field('hpPower')}
              disabled={!!selectedVersion.hpPower}
              className={css.formInput}
              placeholder={t('quote.notSpecifiedShe')}
              autoComplete="off"
              onChange={handleChangeInputNumber}
            />
            <TextField
              name="kwPower"
              label={t('quote.identification.powerKw')}
              value={selectedVersion.kwPower ?? field('kwPower')}
              disabled={!!selectedVersion.kwPower}
              className={css.formInput}
              placeholder={t('quote.notSpecifiedShe')}
              autoComplete="off"
              onChange={handleChangeInputNumber}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(P.2)' })}
            </Typography>
            {identificationCountry === 'fr' && (
              <>
                <TextField
                  name="taxPower"
                  label={t('quote.identification.fiscalPower')}
                  value={selectedVersion.taxPower ?? field('taxPower')}
                  disabled={!!selectedVersion.taxPower}
                  className={css.formInput}
                  placeholder={t('quote.notSpecifiedShe')}
                  autoComplete="off"
                  onChange={handleChangeInputNumber}
                />
                <Typography variant="caption" color="secondary" className={css.helper}>
                  {t('quote.identification.fieldOnRegistrationDocument', { field: '(P.6)' })}
                </Typography>
              </>
            )}
            <TextField
              name="displacement"
              label={t('quote.identification.displacement')}
              value={selectedVersion.displacement ?? field('displacement')}
              disabled={!!selectedVersion.displacement}
              className={css.formInput}
              placeholder={t('quote.notSpecifiedShe')}
              autoComplete="off"
              onChange={handleChangeInputNumber}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(P.1)' })}
            </Typography>

            <TextField
              name="co2"
              label={t('quote.identification.co2')}
              value={i?.regCo2 && i?.regCo2 !== 0 ? i?.regCo2 : field('co2')}
              placeholder={t('quote.notSpecified')}
              className={css.formInput}
              disabled={!!i?.regCo2}
              autoComplete="off"
              onChange={handleChangeInputNumber}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(V.7)' })}
            </Typography>

            <LabeledSeparator title={t('quote.bodyWord')} className={css.separator} />
            <Multiselect
              className={css.formInput}
              label={t('quote.bodyWord')}
              placeholder={t('quote.identification.select.body')}
              defaultOptions={bodyList}
              value={
                selectedVersion.body && {
                  value: selectedVersion.body,
                  label: t(`quote.body.${selectedVersion.body}`),
                }
              }
              disabled={!!selectedVersion.body}
              handleChange={handleSelectChange('body')}
              noOptionsMessage={() => t('quote.noResults')}
              getData={event => getFilteredResults({ list: bodyList })(event)}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.bodyHelper')}
            </Typography>
            <TextField
              name="doors"
              label={t('quote.doorsNumber')}
              value={selectedVersion.doors ?? field('doors')}
              className={css.formInput}
              disabled={!!selectedVersion.doors}
              placeholder={t('quote.notSpecified')}
              autoComplete="off"
              onChange={handleChangeInputNumber}
            />
            <TextField
              name="seats"
              label={t('quote.seatsNumber')}
              value={
                selectedVersion.seats && selectedVersion.seats !== 0
                  ? selectedVersion.seats
                  : field('seats')
              }
              disabled={!!selectedVersion.seats}
              className={css.formInput}
              placeholder={t('quote.notSpecified')}
              autoComplete="off"
              onChange={handleChangeInputNumber}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(S.1)' })}
            </Typography>
            <TextField
              name="color"
              label={t('quote.color')}
              value={i?.regColor ?? i?.color ?? field('color')}
              disabled={!!(i?.regColor ?? i?.color)}
              className={css.formInput}
              placeholder={t('quote.notSpecifiedShe')}
              autoComplete="off"
              {...{ onChange }}
            />
            <LabeledSeparator
              title={t('quote.identification.characteristics')}
              className={css.separator}
            />
            <Multiselect
              className={css.formInput}
              label={t('quote.identification.typeOfRegistrationDocument')}
              placeholder={t('quote.identification.select.type')}
              defaultOptions={regTypeList}
              value={typeOfRegistrationDocumentValue}
              disabled={!!i?.regType}
              handleChange={handleSelectChange('regType')}
              noOptionsMessage={() => t('quote.noResults')}
              getData={event => getFilteredResults({ list: regTypeList })(event)}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(J.1)' })}
            </Typography>
            <TextField
              name="firstRegDate"
              label={<FieldLabel label={t('quote.identification.inssuanceDate')} isRequired />}
              type="date"
              value={field('firstRegDate')}
              className={css.formInput}
              disabled={!!i?.firstRegDate?.date}
              placeholder={t('quote.notSpecifiedShe')}
              autoComplete="off"
              {...{ onChange }}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(B)' })}
            </Typography>
            <>
              <TextField
                name="lastRegDate"
                label={t('quote.identification.lastRegistrationDocumentDate')}
                type="date"
                value={field('lastRegDate')}
                disabled={!!i?.lastRegDate?.date}
                className={css.formInput}
                style={{ minWidth: 250 }}
                placeholder={t('quote.notSpecifiedShe')}
                autoComplete="off"
                {...{ onChange }}
              />
              <Typography variant="caption" color="secondary" className={css.helper}>
                {t('quote.atTheBottomOfRegistrationDocument', '(B)')}
              </Typography>
            </>
            <Multiselect
              className={css.formInput}
              label={t('quote.euroStandard')}
              placeholder={t('quote.identification.select.euroStandard')}
              defaultOptions={euroStandardList}
              value={cond(
                [
                  [
                    !isManual && selectedVersion.euroStandard,
                    {
                      value: selectedVersion.euroStandard,
                      label: selectedVersion.euroStandard,
                    },
                  ],
                  [
                    field('euroStandard'),
                    { value: field('euroStandard'), label: field('euroStandard') },
                  ],
                ],
                null,
              )}
              disabled={!isManual && !!selectedVersion.euroStandard}
              handleChange={handleSelectChange('euroStandard')}
              noOptionsMessage={() => t('quote.noResults')}
              getData={event => getFilteredResults({ list: euroStandardList })(event)}
            />
            <Typography variant="caption" color="secondary" className={css.helper}>
              {t('quote.identification.fieldOnRegistrationDocument', { field: '(V.9)' })}
            </Typography>
            <TextField
              name="price"
              label={t('quote.identification.firstSellPrice')}
              value={selectedVersion.price ?? ''}
              placeholder={t('quote.notSpecified')}
              onChange={handleChangeInputNumber}
              className={css.formInput}
              autoComplete="off"
              disabled
            />
          </>
        )}
      </div>
    </div>
  )
}

const DetailsFormCmp = withStyles({
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formFields: {
    width: '100%',
    maxWidth: 350,
  },
  formInput: {
    width: '100%',
    marginBottom: '2rem',
  },
  separator: {
    marginBottom: '2rem',
  },
  helper: {
    position: 'relative',
    top: '-1.7rem',
    paddingLeft: '1rem',
  },
  draftCreationHelper: {
    position: 'relative',
    top: '-1.7rem',
    paddingLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  draftCreationHelperSpan: {
    marginLeft: '1rem',
  },
  customOptGroup: {
    display: 'block',
    textTransform: 'capitalize',
    borderBottom: '1px solid grey',
    width: '100%',
  },
  itemWithPhase: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'fit-content',
  },
})(DetailsFormJSX)

const DetailsForm: any = withRedux(({ user }) => ({
  preselectedElements: user?.data?.elements,
}))(DetailsFormCmp)

export default DetailsForm
